import { useState } from 'react';

import fablabData from '../../data/fablab';


function FablabNoticePage() {
  const [count, setCount] = useState(3);

  return (
    <div className="content-area">
      <div className="title title--highlighted">
        Fablab Notice
      </div>
      <ul className="notice__list">
        { fablabData.notices.slice(0, count).map((e) => (
          <li key={e.title + e.date}>
            <div>{e.title}</div>
            <div>{e.date}</div>
            <div>{e.content}</div>
          </li>
        ))}
        <div onClick={() => setCount(count + 3)} />
      </ul>
    </div>
  );
}

export default FablabNoticePage;
