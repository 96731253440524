const fablab = {
  "photos": [
    {
      "title": "2021 Spring Basic Design_ Tutorial",
      "image": require("../images/gallery/gallery5.jpg"),
      "date": "2021.03.12",
    },
    {
      "title": "Interaction Prototyping Class",
      "image": require("../images/gallery/gallery4.jpg"),
      "date": "2021.03.04",
    },
    {
      "title": "Product Design Class",
      "image": require("../images/gallery/gallery3.png"),
      "date": "2021.03.04",
    },
    {
      "title": "ID KAIST Model Workshop",
      "image": require("../images/gallery/gallery2.jpg"),
      "date": "2021.03.04",
    },
    {
      "title": "KAIST-AUDI Creative Lounge FABLAB",
      "image": require("../images/gallery/gallery1.png"),
      "date": "2021.03.04",
    },

  ],
};

export default fablab;
