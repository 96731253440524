const fablab = {
  "notices": [
    {
      "title": "New Technician",
      "date": "2022.03.01",
      "content": <>
        A new technician, Mr. Ju, Han-young will work at the KA Lounge and help about your software skills.
      </>,
    },
    {
      "title": "New Laser Cutter Introduction",
      "date": "2021.04.1",
      "content": <>
          New Laser cutter(ML-7050HS) has been installed in Fablab. This equipment was placed in Lasercutter room.
          Please contact the adminstrator before using it.
          <br/> <a href="https://www.notion.so/Laser-Cutter-ML-7050HS-83065f3b129f45579ea116fc42f7a337" target="_blank">New Laser Cutter(ML-7050HS)</a>
        </>,
    },
    {
      "title": "Laser Cutter Maintenance Schedule Change",
      "date": "2021.03.17",
      "content": <>
        The Lasercutter exhaust filter replacement work has been postponed from 14:00 to 16:00 on March 22.
      </>,
    },
    {
      "title": "Laser Cutter Maintenance Schedule",
      "date": "2021.03.11",
      "content": <>
        The exhaust filters of Lasercutter ils9.150D will be replaced from 14:00 to 16:00 on March 19. This equipment will not be available during this time.
      </>,
    },
    {
      "title": "IDKAIST Fablab Webpage Open",
      "date": "2021.03.11",
      "content": <>
        Fablab webpage was Opend on March 11. On this page, we will notify the news and announcements related to fablab.
        Please check the notice before using Fablab.
      </>,
    },

  ],
  "equipments": [
//Laser Cutter Room
    {
      "name": "ILS 9.150D",
      "image": require("../images/fablab/ils9150d.png"),
      "location": "Laser Cutter Room",
      "description": "Laser Cutter 900x600mm",
      "link": "https://bold-pink-e12.notion.site/Laser-Cutter-ILS9-150D-f3db282cdacb4dfdb1626ccb99d2eaf3"
    },
    {
      "name": "ML-7050HS",
      "image": require("../images/fablab/equipment/lc.png"),
      "location": "Laser Cutter Room",
      "description": "Laser Cutter 700x500mm",
      "link": "https://bold-pink-e12.notion.site/Laser-Cutter-ML-7050HS-8b08b0f8b0d348bc903c6608aa655f6f"
    },
    //3D Printer Room
    {
      "name": "Sindoh DP200",
      "image": require("../images/fablab/dp200.png"),
      "location": "3D Printer Room",
      "description": "FDM 3D Printer 210x200x195mm",
      "link": "https://bold-pink-e12.notion.site/3D-Printer-3DWOX-DP200-8ee32dffbc3940479b4aa50a9559b0e3"
    },
    {
      "name": "Sindoh 3DWOX 1X",
      "image": require("../images/fablab/sindoh1x.png"),
      "location": "3D Printer Room",
      "description": "FDM 3D Printer 228x200x300mm",
      "link": "https://bold-pink-e12.notion.site/3D-Printer-3DWOX-1X-c332133049174b0983dafd00c8a8787c"
    },
    {
      "name": "Sindoh 3DWOX 1",
      "image": require("../images/fablab/sindoh1x.png"),
      "location": "3D Printer Room",
      "description": "FDM 3D Printer 223x220x205mm",
      "link": "https://bold-pink-e12.notion.site/3D-Printer-3DWOX-1-bdc12e55fc9d4c1fa3de22468213d951"
    },
    // CNC Machine Room
    {
      "name": "LM6090 CNC Milling",
      "image": require("../images/fablab/lm6090.png"),
      "location": "CNC Machine Room",
      "description": "CNC Milling 900x600x95mm",
      "link": "https://bold-pink-e12.notion.site/CNC-Milling-MR-LM6090-df0ccacebd714c14b85154cd4012ac2c"
    },
    {
      "name": "mini Drill Press",
      "image": require("../images/fablab/equipment/minidrillpress.png"),
      "location": "CNC Machine Room",
      "description": "PROXXON Mini Drill Press",
      "link": "https://bold-pink-e12.notion.site/Drill-Press-mini-Proxxon-05eea4812abf43c7b53e94a10f4e68d7"
    },
    {
      "name": "mini BandSaw",
      "image": require("../images/fablab/equipment/minibandsaw.png"),
      "location": "CNC Machine Room",
      "description": "PROXXON Mini Band Saw",
      "link": "https://bold-pink-e12.notion.site/Bandsaw-mini-Proxxon-ed3631b68e4249ee8f4e0534e30a7787"
    },
    {
      "name": "Disc Sander",
      "image": require("../images/fablab/equipment/discsander.png"),
      "location": "CNC Machine Room",
      "description": "PROXXON Disc Sander",
      "link": "https://bold-pink-e12.notion.site/Discsander-mini-Proxxon-0dd7bc6770ec47c6a04c78b6df58fd42"
    },
    //Electronic Workshop
    {
      "name": "Soldering Tools",
      "image": require("../images/fablab/equipment/ironstation.png"),
      "location": "Electronic Workshop",
      "description": "Hakko FX-888 iron station",
      "link": "https://bold-pink-e12.notion.site/Soldering-Iron-Tool-FX-888D-6f4c8455c96249b8aea82c1bcfef4fba"
    },
    {
      "name": "Electronic Parts",
      "image": require("../images/fablab/equipment/electronics_part.png"),
      "location": "Electronic Workshop",
      "description": "Electronic Parts",
      "link": "https://bold-pink-e12.notion.site/Fablab-Materials-4cfa6bbb1dcb4f4290ba7d33be10d377"
    },


  ],
  "administrator": {
    "name": "Hanyoung JU",
    "type": "Fablab Administrator",
    "image": require("../images/fablab/JU.JPG"),
    "phone": "042.350.4595",
    "email": "gene1696@kaist.ac.kr",
    "slots": [
      {
        "day": "Weekday",
        "time": "09:00 ~ 18:00",
      },
    ]
  },
  "assistants": [
    {
      "name": "Geunyong Park",
      "type": "Fablab Assistant",
      "image": require("../images/fablab/assistant.png"),
      "slots": [
        {
          "day": "Mon",
          "time": "16:00 ~ 18:00",
        },

        {
          "day": "Tue",
          "time": "16:00 ~ 18:00",
        },
        {
          "day": "Thu",
          "time": "12:20 ~ 14:20",
        },
      ]
    },

  ]
};

export default fablab;
