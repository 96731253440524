//"Wood Workshop", "Machine Workshop", "Metal Workshop", "Model Workshop", "Coloring Space"];
const workshop = {
  "notices": [
    {
      "title": "2022 safety inspection",
      "date": "2022.07.27",
      "content": <>
        2022.08.09. The use of workshop will be restricted due to the safety inspection of the laboratory. 
      </>,
    },
    {
      "title": "IDKAIST Workshop Webpage Open",
      "date": "2021.03.11",
      "content": <>
        Workshop webpage was Opend on March 11. On this page, we will notify the news and announcements related to workshop.
        Please check the notice before using Workshop.
      </>,
    },
  ],
  "equipments": [
    //Wood Workshop Equipment
    {
      "name": "1325 CNC ROUTER",
      "image": require("../images/workshop/equipment/1325 CNC BUGWANG GTC.png"),
      "location": "Wood Workshop",
      "description": "CNC ROUTER 1300x2500mm",
      "link": "https://www.notion.so/CNC-Router-GTC-1325O-a7661e914a8148ddbc0e695edf3b7f06"
    },
    {
      "name": "Band Saw",
      "image": require("../images/workshop/equipment/Band saw LAGUNA.png"),
      "location": "Wood Workshop",
      "description": "LAGUNA 18' Bandsaw ",
      "link": "https://www.notion.so/Band-SAW-LAGUNA-18BX-6fad5db78ed54ad39bdac02ca64234a8"
    },
    {
      "name": "Beltsander",
      "image": require("../images/workshop/equipment/Beltsander SHARPMAX.png"),
      "location": "Wood Workshop",
      "description": "SHARPMAX Beltsander",
      "link": "https://www.notion.so/Belt-Sander-SHARP-MAX-KS6108-3ebb9d7e0faf40408bbf4ad9795bd100"
    },
    {
      "name": "Table Saw ",
      "image": require("../images/workshop/equipment/Table saw SAWSTOP.png"),
      "location": "Wood Workshop",
      "description": "SAWSTOP Tablesaw",
      "link": "https://www.notion.so/Table-SAW-SAWSTOP-PCS31230-00b71546dc7b4e718e23b3e6ea82a7d4"
    },
    {
      "name": "Miter Saw ",
      "image": require("../images/workshop/equipment/Miter saw Makita.png"),
      "location": "Wood Workshop",
      "description": "Makita Miter Saw ",
      "link": "https://www.notion.so/Miter-SAW-MAKITA-LS1019L-c8fc0ab6ba214854aa9fc3b843054ae6"
    },
    //Machine Workshop equipment
    {
      "name": "Lathe Machine",
      "image": require("../images/workshop/equipment/Lathe EMCO.png"),
      "location": "Machine Workshop",
      "description": "Lathe EMCO",
      "link": "https://www.notion.so/Lathe-Machine-Emco-Unknown-d75600c80dae4fcb8620ce766df403da"
    },
    {
      "name": "Discsander",
      "image": require("../images/workshop/equipment/Discsander SHARPMAX.png"),
      "location": "Machine Workshop",
      "description": "SHARPMAX Discsander",
      "link": "https://www.notion.so/Disc-Sander-SHARP-MAX-WD-12-cac271c879704abaa48d91feb2b2917a"
    },
    {
      "name": "Table Drill",
      "image": require("../images/workshop/equipment/Table Drill WOOSEONG.png"),
      "location": "Machine Workshop",
      "description": "WOOSEONG Table Drill",
      "link": "https://www.notion.so/Drill-Machine-Wooseong-WSD-13-ea9cfa412c97494dbc20e33ec99cd0ee"
    },
    {
      "name": "Scroll saw",
      "image": require("../images/workshop/equipment/Scroll saw PROXXON.png"),
      "location": "Machine Workshop",
      "description": "PROXXON Scroll saw",
      "link": "https://www.notion.so/Scroll-SAW-Proxxon-DSH-cd60b31c971e4b32885b085946513e97"
    },
    {
      "name": "Mini Milling",
      "image": require("../images/workshop/equipment/Mini Milling MANIX.png"),
      "location": "Machine Workshop",
      "description": "MANIX Mini Milling",
      "link": "https://www.notion.so/Milling-Machine-MANIX-MM-250-S3-15b55cd4fc0f41f285f64c8b43856894"
    },
    {
      "name": "Sand Blaster",
      "image": require("../images/workshop/equipment/SANDBLAST.png"),
      "location": "Machine Workshop",
      "description": "Ilbeom Sand Blaster",
      "link": "https://www.notion.so/Sand-Blaster-ILBEOM-59e9b6c1c69c4198a805bf806b70e964"
    },

    //Metal Worksshop equipment
    {
      "name": "Welding Machine SEDA ACDC",
      "image": require("../images/workshop/equipment/Welding Machine SEDA ACDC.png"),
      "location": "Metal Workshop",
      "description": "SEDA ACDC Welding Machine",
      "link": "https://www.notion.so/Welding-Machine-SEDA-AC-DC-250-c541025319ae424bbb93ed2012a2e864"
    },
    //Model Workshop equipment
    {
      "name": "Vacuum Forming Machine",
      "image": require("../images/workshop/equipment/Vaquform DT2.png"),
      "location": "Model Workshop",
      "description": "Vaquform DT2",
      "link": "https://www.notion.so/Vacuum-Forming-Vaquform-DT2-521fe011f442493e88f9ae39073ef1f4"
    },
    {
      "name": "UV Printer",
      "image": require("../images/workshop/equipment/UV Printer Z4 NC GLOBAL.png"),
      "location": "Model Workshop",
      "description": "UV Printer Z4 NC GLOBAL",
      "link": "https://www.notion.so/UV-Printer-NC-UV3-A4-Z1-2540b2d7e83d4ca5bedde0107a91b985"
    },
    {
      "name": "Hot Wire Cutter",
      "image": require("../images/workshop/equipment/Hot Wire MANIX.png"),
      "location": "Model Workshop",
      "description": "MANIX Hot Wire Cutter",
      "link": "https://www.notion.so/Heating-Cutter-MANIX-2S-6c64dfe222164cc3b93b0e53ce15957e"
    },
    {
      "name": "Vacuum Deforming Machine",
      "image": require("../images/workshop/equipment/Vacuum Deforming.png"),
      "location": "Model Workshop",
      "description": "Vacuum Deforming",
      "link": "https://www.notion.so/Vacuum-Deforming-56f3c886c4a447c19be5e8d1a4f1e365"
    },
    //Coloring Space
    {
      "name": "Sray Booth",
      "image": require("../images/workshop/equipment/COLOR BOOTH.png"),
      "location": "Coloring Space",
      "description": "Spray Booth Facility",
      "link": "https://www.notion.so/Spray-Booth-HUTECH-09ee696cc2204f0abebdc177425ddd84"
    },
    {
      "name": "Materials",
      "image": require("../images/workshop/equipment/materials.png"),
      "location": "Etc",
      "description": "Materials: Wood, MDF, ABS, Aluminum etc.. ",
      "link": "https://www.notion.so/Materials-3c71981ea3094034b87c49cce06fce33"
    },
  ],
  "administrator": {
    "name": "Seungyong Jeong",
    "type": "Workshop Administrator",
    "image": require("../images/workshop/jsy.png"),
    "phone": "042.350.4593",
    "email": "syj94820@kaist.ac.kr",
    "slots": [
      {
        "day": "Weekday",
        "time": "09:00 ~ 18:00",
      },
    ]
  },
  "assistants": [
    {
      "name": "TBD",
      "type": "Workshop Assistant",
      "image": require("../images/fablab/assistant.png"),
      "slots": [
        {
          "day": "Mon",
          "time": "14:00 ~ 16:00",
        },
        {
          "day": "Wed",
          "time": "14:00 ~ 16:00",
        },
      ]
    },
  ]
};
/* backup
"assistants": [
  {
    "name": "-",
    "type": "-",
    "image": require("../images/assistant.png"),
    "slots": [
      {
        "day": "Mon",
        "time": "09:00 ~ 18:00",
      },
      {
        "day": "Thu",
        "time": "15:00 ~ 18:00",
      },
    ]
  },
  {
    "name": "-",
    "type": "-",
    "image": require("../images/assistant.png"),
    "slots": [
      {
        "day": "Mon",
        "time": "09:00 ~ 18:00",
      },
      {
        "day": "Thu",
        "time": "15:00 ~ 18:00",
      },
    ]
  },
  {
    "name": "-",
    "type": "-",
    "image": require("../images/assistant.png"),
    "slots": [
      {
        "day": "Mon",
        "time": "09:00 ~ 18:00",
      },
      {
        "day": "Thu",
        "time": "15:00 ~ 18:00",
      },
    ]
  },
]
};
*/
//"Wood Workshop", "Machine Workshop", "Metal Workshop", "Model Workshop", "Coloring Space"
export default workshop;
