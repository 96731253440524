import { useState } from 'react';

import galleryData from '../data/gallery';


function GalleryPage() {
  const NUM_IMAGES_PER_PAGE = 10;

  const [ page, setPage ] = useState(1);
  const [ selectedPhoto, setSelectedPhoto ] = useState(null);

  return (
    <div className="content-area">
      <div className="gallery__images">
        {
          galleryData.photos.slice(NUM_IMAGES_PER_PAGE*(page-1), NUM_IMAGES_PER_PAGE*page).map((p) => (
            <div onClick={() => setSelectedPhoto(p)}>
              <img src={p.image.default || p.image} alt={p.title} className="photo" />
              <div>
                <div>{p.title}</div>
                <div>{p.date}</div>
              </div>
            </div>
          ))
        }
      </div>
      <div className="gallery__pages">
        {
          Array.from(
            { length: Math.ceil(galleryData.photos.length / NUM_IMAGES_PER_PAGE) },
            (_, i) => (
              <div className={(page === i+1) ? "current" : ""} onClick={() => setPage(i+1)}>
                {i+1}
              </div>
            )
          )
        }
      </div>
      {
        selectedPhoto
          ? (
            <div className="gallery__modal">
              <div className="gallery__modal__background" onClick={() => setSelectedPhoto(null)} />
              <div className="gallery__modal__content">
                <img src={selectedPhoto.image.default || selectedPhoto.image} alt={selectedPhoto.title} className="photo" />
                <span onClick={() => setSelectedPhoto(null)} />
              </div>
            </div>
          )
          : undefined
      }
    </div>
  );
}

export default GalleryPage;
