import fablabData from '../../data/fablab';

import fablabLogo from '../../images/fablab-logo.png';

import fablabImage1 from '../../images/about-fablab-1.jpg';
import fablabImage2 from '../../images/about-fablab-2.jpg';
import fablabFloorplan from '../../images/fablab/fablab-floorplan.png';


function FablabHowToUsePage() {
  return (
    <div className="content-area">
      <div className="how-to-use__intro">
        <div className="how-to-use__intro__images">
          <img className="how-to-use__intro__images__small photo" src={fablabImage1} alt="fablabImage1" />
          <img className="how-to-use__intro__images__big photo" src={fablabImage2} alt="fablabImage2" />
        </div>
        <div className="how-to-use__intro__description">
          <img className="how-to-use__intro__description__logo" src={fablabLogo} alt="Fablab logo" />
          <div className="how-to-use__intro__description__line" />
          <div className="how-to-use__intro__description__title">
            FABLAB
          </div>
          <div className="how-to-use__intro__description__content">
            ID FABLAB is the incubation center for ID KAIST members who aspire to
            sprout their creativity and bring the great ideas into real world.
            To incubate entrepreneurs and startups as real innovator,
            it provides end-to-end mentoring from ideation to market introduction.
            ID members can use this facility and equipment.
            ID FABLAB consists of four section : Laser cutter room,
            3D printer room, CNC machine room, Electronic workshop.
            Realize your creative imagination by using digital fabrcation equipment.
            * Please look at the VR map and floor plan for details.
          </div>
        </div>
      </div>
      <div className="divider" />
      <div className="title">
        VR Map
      </div>
      <iframe
        className="matterport"
        width="853"
        height="480"
        src="https://my.matterport.com/show/?m=Ao7kGAAWszn"
        title="fablab-matterport"
        frameborder="0"
        allowfullscreen
        allow="xr-spatial-tracking"
      />
      <div className="divider" />
      <div className="title">
        Floor Map
      </div>
      <img className="how-to-use__map" src={fablabFloorplan} alt="Floor Map" />
      <div className="divider" />
      <div className="how-to-use__people">
        <div className="how-to-use__people__row">
          <div>
            <img src={fablabData.administrator.image.default} alt="fablabAdmin" />
            <div>
              <div>{fablabData.administrator.name}</div>
              <div>{fablabData.administrator.type}</div>
              <ul>
                <li><span>Weekday</span>09:00 ~ 18:00</li>
              </ul>
              <div>
                <div>{fablabData.administrator.phone}</div>
                <div>{fablabData.administrator.email}</div>
              </div>
            </div>
          </div>
        </div>
        {[...Array(Math.ceil(fablabData.assistants.length / 2))].map((_, i) => (
          <div className="how-to-use__people__row">
            {fablabData.assistants.slice(i*2, (i+1)*2).map((a) => (
              <div>
              <img src={a.image.default} alt={a.name} />
              <div>
                <div>{a.name}</div>
                <div>{a.type}</div>
                <ul>
                  {a.slots.map((s) => (
                    <li><span style={s.day.trim().length===3 ? {width:"44px"} : null}>{s.day}</span>{s.time}</li>
                  ))}
                </ul>
              </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default FablabHowToUsePage;
