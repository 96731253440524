import { useState } from 'react';

import fablabData from '../../data/fablab';


function FablabEquipmentPage() {
  const filterContents = ["3D Printer Room", "Laser Cutter Room", "CNC Machine Room", "Electronic Workshop"];

  const [filter, setFilter] = useState("All");

  console.log(filter);

  const getFilteredEquipments = () => {
    if (filter === 'All') {
      return fablabData.equipments;
    }
    if (filterContents.find((f) => (f === filter))) {
      return fablabData.equipments.filter((e) => (e.location === filter));
    }
    return fablabData.equipments.filter((e) => !filterContents.find((f) => (e.location === f)));
  }

  return (
    <div className="content-area">
    <div className="title title--highlighted">
      Fablab Equipment
    </div>
      <div className="equipment__filter">
        {
          ["All", ...filterContents, "Etc"].map((n) => (
            <div className={(n === filter ? "active" : "")} onClick={()=>setFilter(n)}>{n}</div>
          ))
        }
      </div>
      <ul className="equipment__list">
        { getFilteredEquipments().map((e) => (
          <a href={e.link} target="_blank" rel="noreferrer" key={e.name}><li>
            <div>
              <div>{e.name}</div>
              <div>{e.description}</div>
              <img src={e.image.default} alt={e.name} />
            </div>
            <span />
          </li></a>
        ))}
      </ul>
    </div>
  );
}

export default FablabEquipmentPage;
