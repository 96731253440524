
function Footer() {
  return (
    <footer>
      <div>
        042) 350 - 4595. 4593
      </div>
      <div>
        KAIST Department of Industrial<br />
        Design Makerspace
      </div>
      <div>
        ⓒ 2021 idKAIST. All Rights Reserved.
      </div>
    </footer>
  );
}

export default Footer;
