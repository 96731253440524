import { Link } from 'react-router-dom';

import fablabImage1 from '../images/about-fablab-1.jpg';
import fablabImage2 from '../images/about-fablab-2.jpg';
import workshopImage1 from '../images/about-workshop-1.jpg';
import workshopImage2 from '../images/about-workshop-2.jpg';


function AboutPage() {
  return (
    <div className="content-area">
      <div className="about__row">
        <Link to="/fablab/how-to-use" className="about__row__images">
          <img className="about__row__images__small photo" src={fablabImage1} alt="fablabImage1" />
          <img className="about__row__images__big photo" src={fablabImage2} alt="fablabImage2" />
          <span />
        </Link>
        <div className="about__row__title">
          Fablab
        </div>
        <div className="about__row__description">
          ID FABLAB is the incubation center for ID KAIST members who aspire to
          sprout their creativity and bring the great ideas into real world.
          To incubate entrepreneurs and startups as real innovator, it provides
          end-to-end mentoring from ideation to market introduction.
          ID members can use this facility and equipment.
          ID FABLAB consists of four section : Laser cutter room, 3D printer room,
          CNC machine room, Electronic workshop. Realize your creative imagination
          by using digital fabrcation equipment.
        </div>
      </div>
      <div className="divider" />
      <div className="about__row">
        <Link to="/workshop/how-to-use" className="about__row__images">
          <img className="about__row__images__big photo" src={workshopImage1} alt="workshopImage1" />
          <img className="about__row__images__small photo" src={workshopImage2} alt="workshopImage2" />
          <span />
        </Link>
        <div className="about__row__title">
          Workshop
        </div>
        <div className="about__row__description">
        ID WORKSHOP is a space where ID KAIST students' ideas can be embodied through various  equipment and a wide working environment.
  Based on four prototyping steps, including planning, shaping, polishing and finishing, we provide mentoring that can be completed from idea to product, and provide solutions that can realize abstract ideas through training the operation of various processing equipment.
  ID WORKSHOP consists of 1325 size CNC, Vacuum Forming, Welding , Wood work machines and more than 10 different equipment. Be specific your ideas in your head with the workshop.

        </div>
      </div>
    </div>
  );
}

export default AboutPage;
