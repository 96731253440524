import './App.scss';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Header from './components/Header';
import Footer from './components/Footer';

import AboutPage from './pages/AboutPage';
import FablabtNoticePage from './pages/fablab/FablabNoticePage';
import FablabHowToUsePage from './pages/fablab/FablabHowToUsePage';
import FablabEquipmentPage from './pages/fablab/FablabEquipmentPage';
import WorkshopNoticePage from './pages/workshop/WorkshopNoticePage';
import WorkshopHowToUsePage from './pages/workshop/WorkshopHowToUsePage';
import WorkshopEquipmentPage from './pages/workshop/WorkshopEquipmentPage';
import GalleryPage from './pages/GalleryPage';
import MainPage from './pages/MainPage';

function App() {
  return (
    <Router>
      <Header />
      <Switch>
        <Route exact path="/about"><AboutPage /></Route>
        <Route exact path="/fablab/notice"><FablabtNoticePage /></Route>
        <Route exact path="/fablab/how-to-use"><FablabHowToUsePage /></Route>
        <Route exact path="/fablab/equipment"><FablabEquipmentPage /></Route>
        <Route exact path="/workshop/notice"><WorkshopNoticePage /></Route>
        <Route exact path="/workshop/how-to-use"><WorkshopHowToUsePage /></Route>
        <Route exact path="/workshop/equipment"><WorkshopEquipmentPage /></Route>
        <Route exact path="/gallery"><GalleryPage /></Route>
        <Route exact path="/"><MainPage /></Route>
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
