import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import logo from '../images/logo.png';


function Header() {
  const [isSlideOpen, setMenuOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);

  const location = useLocation();

  useEffect(() => {
    setMenuOpen(false);
    setOpenDropdown(null);
  }, [location]);

  return (
    <header className="App-header">
      <div className="left-menu">
        <Link to="/">
          <img src={logo} alt="MAKERSPACE" />
        </Link>
      </div>
      <div className="center-menu">
        <div>
          <Link to="/about">About</Link>
        </div>
        <div>
          <span>Fablab</span>
          <ul>
            <li><Link to="/fablab/how-to-use">How To Use</Link></li>
            <li><Link to="/fablab/notice">Notice</Link></li>
            <li><Link to="/fablab/equipment">Equipment</Link></li>
          </ul>
        </div>
        <div>
          <span>Workshop</span>
          <ul>
            <li><Link to="/workshop/how-to-use">How To Use</Link></li>
            <li><Link to="/workshop/notice">Notice</Link></li>
            <li><Link to="/workshop/equipment">Equipment</Link></li>
          </ul>
        </div>
        <div>
          <Link to="/gallery">Gallery</Link>
        </div>
      </div>
      <div className="right-menu">
        <span onClick={() => setMenuOpen(true)}>
        </span>
      </div>
      <div className={["slide-menu", (isSlideOpen ? "slide-menu--open" : "")].join(" ")}>
        <span onClick={() => setMenuOpen(false)}>
        </span>
        <div>
          <Link to="/about">About<span className="link" /></Link>
        </div>
        <div>
          <span onClick={() => setOpenDropdown(openDropdown === "FABLAB" ? null : "FABLAB")}>Fablab<span className={openDropdown === "FABLAB" ? "close" : "more"} /></span>
          <ul style={{ display: (openDropdown === "FABLAB" ? "block" : "none"), }}>
            <li><Link to="/fablab/how-to-use">How To Use</Link></li>
            <li><Link to="/fablab/notice">Notice</Link></li>
            <li><Link to="/fablab/equipment">Equipment</Link></li>
          </ul>
        </div>
        <div>
          <span onClick={() => setOpenDropdown(openDropdown === "WORKSHOP" ? null : "WORKSHOP")}>Workshop<span className={openDropdown === "WORKSHOP" ? "close" : "more"} /></span>
          <ul style={{ display: (openDropdown === "WORKSHOP" ? "block" : "none"), }}>
            <li><Link to="/workshop/how-to-use">How To Use</Link></li>
            <li><Link to="/workshop/notice">Notice</Link></li>
            <li><Link to="/workshop/equipment">Equipment</Link></li>
          </ul>
        </div>
        <div>
          <Link to="/gallery">Gallery<span className="link" /></Link>
        </div>
        <div className="slide-menu__footer">
          <div>
            042) 350 - 4595. 4593
          </div>
          <div>
            KAIST Department of Industrial <br />
            Design Makerspace
          </div>
          <div>
            ⓒ 2021 idKAIST. All Rights Reserved.
            <span />
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
