/*
{
  "content": <>
    This is template.
  </>,
  "link": "/fablab/notice",
  "visible_from": new Date(2021, 3-1, 29),
  "visible_to": new Date(2021, 4-1, 5),
},
*/

const main = {
  "popups": [

    {
      "content": <>
        New Lasercutter(ML-7050HS) has been introduced in Fablab.
        <br/> This equipment was placed in Lasercutter Room.<br/>

        </>,
      "link": "/fablab/equipment",
      "visible_from": new Date(2021, 4-1, 1),
      "visible_to": new Date(2021, 4-1, 8),
    },
  ],
};

export default main;
