import workshopData from '../../data/workshop';

import workshopLogo from '../../images/workshop-logo.png';

import workshopImage1 from '../../images/about-workshop-1.jpg';
import workshopImage2 from '../../images/about-workshop-2.jpg';
import workshopFloorplan from '../../images/workshop/workshop-floorplan.png';



function WorkshopHowToUsePage() {
  return (
    <div className="content-area">
      <div className="how-to-use__intro">
        <div className="how-to-use__intro__images">
          <img className="how-to-use__intro__images__small photo" src={workshopImage2} alt="workshopImage2" />
          <img className="how-to-use__intro__images__big photo" src={workshopImage1} alt="workshopImage1" />
        </div>
        <div className="how-to-use__intro__description">
          <img className="how-to-use__intro__description__logo how-to-use__intro__description__logo--workshop" src={workshopLogo} alt="Workshop logo" />
          <div className="how-to-use__intro__description__line" />
          <div className="how-to-use__intro__description__title">
            WORKSHOP
          </div>
          <div className="how-to-use__intro__description__content">
          ID WORKSHOP is a space where ID KAIST students' ideas can be embodied through various  equipment and a wide working environment.
  Based on four prototyping steps, including planning, shaping, polishing and finishing, we provide mentoring that can be completed from idea to product, and provide solutions that can realize abstract ideas through training the operation of various processing equipment.
  ID WORKSHOP consists of 1325 size CNC, Vacuum Forming, Welding , Wood work machines and more than 10 different equipment. Be specific your ideas in your head with the workshop.
  * Please look at the VR map and floor plan for details.

          </div>
        </div>
      </div>
      <div className="divider" />
      <div className="title">
        VR Map
      </div>

      <iframe
        className="matterport"
        width="853"
        height="480"
        src="https://my.matterport.com/show/?m=oE6LGsRvC6W"
        title="workshop-matterport"
        frameborder="0"
        allowfullscreen
        allow="xr-spatial-tracking"
      />
      <div className="divider" />
      <div className="title">
        Floor Map
      </div>
      <img className="how-to-use__map" src={workshopFloorplan} alt="Floor Map" />
      <div className="divider" />
      <div className="how-to-use__people">
        <div className="how-to-use__people__row">
          <div>
            <img src={workshopData.administrator.image.default} alt="fablabAdmin" />
            <div>
              <div>{workshopData.administrator.name}</div>
              <div>{workshopData.administrator.type}</div>
              <ul>
                <li><span>Weekday</span>09:00 ~ 18:00</li>
              </ul>
              <div>
                <div>{workshopData.administrator.phone}</div>
                <div>{workshopData.administrator.email}</div>
              </div>
            </div>
          </div>
        </div>
        {[...Array(Math.ceil(workshopData.assistants.length / 2))].map((_, i) => (
          <div className="how-to-use__people__row">
            {workshopData.assistants.slice(i*2, (i+1)*2).map((a) => (
              <div>
              <img src={a.image.default} alt={a.name} />
              <div>
                <div>{a.name}</div>
                <div>{a.type}</div>
                <ul>
                  {a.slots.map((s) => (
                    <li><span style={s.day.trim().length===3 ? {width:"44px"} : null}>{s.day}</span>{s.time}</li>
                  ))}
                </ul>
              </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default WorkshopHowToUsePage;
