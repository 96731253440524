import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import mainImage1 from '../images/main-1.jpg';
import mainImage2 from '../images/main-2.jpg';
import mainImage3 from '../images/main-3.jpg';
import mainImage4 from '../images/main-4.jpg';
// import mainImage5 from '../images/main-5.jpg';

import mainData from '../data/main';


function MainPage() {
  const INTERVAL_MILLIS = 10;
  const START_TIME = 1500;

  const [millis, setMillis] = useState(0);
  const imageArea = useRef(null);
  const imageContainer = useRef(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setMillis(millis + INTERVAL_MILLIS);
    }, INTERVAL_MILLIS);

    return () => {
      clearTimeout(timeout);
    }
  }, [millis]);

  var imageLeft = (millis - START_TIME) * (-0.05);
  if (imageLeft > 0) {
    imageLeft = 0;
  }
  else if (imageLeft < -(imageContainer.current?.clientWidth - imageArea.current?.clientWidth)) {
    imageLeft = -(imageContainer.current?.clientWidth - imageArea.current?.clientWidth);
  }

  const now = new Date(Date.now());
  const visiblePopups = mainData.popups.filter((p) => ((p.visible_from)<=now && now <= p.visible_to));
  const popup = visiblePopups.length>0 ? visiblePopups[0] : null;
  const [isPopupOpen, setPopupOpen] = useState(popup != null);

  return (
    <div className="content-area">
      {
        isPopupOpen
          ? (
            <div className="main__modal">
              <div className="main__modal__background" onClick={() => setPopupOpen(false)} />
              <div className="main__modal__body">
                <div className="main__modal__body__title">Notice</div>
                <span onClick={() => setPopupOpen(false)} />
                <div className="main__modal__body__content">
                  {popup.content}
                </div>
                <Link to={popup.link} />
              </div>
            </div>
          )
          : null
      }
      <div className="main__images" ref={imageArea}>
        <div style={{ left: imageLeft, }} ref={imageContainer} >
          { [...Array(10)].map((e) => (
            <>
              <img src={mainImage1} alt="mainImage1" className="photo" />
              <img src={mainImage2} alt="mainImage2" className="photo" />
              <img src={mainImage3} alt="mainImage3" className="photo" />
              <img src={mainImage4} alt="mainImage4" className="photo" />
              {/*<img src={mainImage5} alt="mainImage5" className="photo" />*/}
            </>
          )) }
        </div>
      </div>
      <div className="main__intro">
        <div className="main__intro__row">
            <div className="main__intro__row__images">
              <img src={mainImage1} alt="mainImage1" className="photo" />
              <img src={mainImage2} alt="mainImage2" className="photo" />
              <img src={mainImage3} alt="mainImage3" className="photo" />
            </div>
            <div className="main__intro__row__title">
              FABLAB
            </div>
            <div className="main__intro__row__description">
              3D Printing <br />
              Laser Cutting <br />
              Electronics etc.
              <Link className="main__intro__row__description__link" to="/fablab/how-to-use" />
            </div>
        </div>
        <div className="divider" />
        <div className="main__intro__row">
            <div className="main__intro__row__images">
              <img src={mainImage1} alt="mainImage1" className="photo" />
              <img src={mainImage2} alt="mainImage2" className="photo" />
              <img src={mainImage3} alt="mainImage3" className="photo" />
            </div>
            <div className="main__intro__row__title">
              WORKSHOP
            </div>
            <div className="main__intro__row__description">
              Woodworking <br />
              Vaccum forming <br />
              Metal welding etc.
              <Link className="main__intro__row__description__link" to="/workshop/how-to-use" />
            </div>
        </div>
      </div>
    </div>
  );
}

export default MainPage;
